import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';

import TodoList from './Todo';
import Login from './Login';
import Signup from './Signup';
import Verify from './Verify';
import Home from './Home';

export enum Page {
  Todo=1,
  Login,
  Signup,
  Verify,
  Home
}

function App() {
  const pb = new PocketBase(process.env.REACT_APP_API_URL);
  const [page, setPage] = useState<Page>(pb.authStore.isValid && pb.authStore.model ? Page.Todo : Page.Home);

  switch(page){
    case Page.Home:
      return <Home pb={pb} setPage={setPage}/>
    case Page.Verify:
      return <Verify pb={pb} setPage={setPage}/>
    case Page.Todo:
      return <TodoList pb={pb} setPage={setPage}/>
    case Page.Login:
      return <Login pb={pb} setPage={setPage}/>
    case Page.Signup:
      return <Signup pb={pb} setPage={setPage}/>
    default:
      return <b> uh oh, something went wrong </b>
  }
}

export default App;
