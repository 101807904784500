import React, { useState, useEffect } from 'react';

interface TimeFormat {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

interface CountdownTimerProps {
  initialTimeInSeconds?: number;
  onComplete?: () => void;
  className?: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialTimeInSeconds = 7 * 24 * 3600,  // Default to 7 days
  onComplete,
  className = ''
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTimeInSeconds);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1 && onComplete) {
            onComplete();
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [timeLeft, onComplete]);

  const formatTime = (seconds: number): TimeFormat => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return {
      days: String(days).padStart(2, '0'),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(remainingSeconds).padStart(2, '0')
    };
  };

  const time: TimeFormat = formatTime(timeLeft);

  return (
    <p className="font-mono font-semibold pr-5">{time.days}:{time.hours}:{time.minutes}:{time.seconds}</p>
  );
};

export default CountdownTimer;