import { useState, useEffect } from 'react'
import { Plus, Trash2, Check, X } from 'lucide-react'
import PocketBase, {ClientResponseError, RecordModel}  from 'pocketbase'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Page } from "./App"
import Message, { Level } from "./Message"
import CountdownTimer from './CountdownTimer';
import Logo from './Logo';

class Todo {
  title: string
  description: string
  id: string
  completed: boolean
  user: string
  punishment_email: string
  due: Date

  constructor(data: any) {
    this.title = data["title"]
    this.description = data["description"]
    this.id = data["id"]
    this.completed = data["completed"]
    this.user = data["user"]
    this.punishment_email = data["punishment_email"]
    this.due = data["due"]
  }
}


function TodoList({pb, setPage}: {pb: PocketBase, setPage: Function}) {
  const [todos, setTodos] = useState<Array<Todo>>([])
  const [message, setMessage] = useState({message: "", level: Level.Info})

  let id = ""
  if (pb.authStore.model && "id" in pb.authStore.model) {
    //console.log(pb.authStore.model)
    id = pb.authStore.model.id
  } 
  const [newTodo, setNewTodo] = useState(new Todo({ title: '', description: '', completed: false, user: id, punishment_email: '', due: new Date()}))

  useEffect(() => {
    fetchTodos()
  }, [])

  const fetchTodos = async () => {
    try {
      // TODO?
      const newTodos = await pb.collection('todos').getFullList<Todo>({
          sort: '-created',
          filter: 'user.id="' + id + '"'
      })
      setTodos(newTodos)
    } catch (error) {
      if (error instanceof ClientResponseError && error.message.includes("autocancelled")) { 
        // print nothing
      }else if (error instanceof ClientResponseError) {
        let message  = (Object.values(error.response.data)[0] as {[key: string]: any}).message || "Unknown Error";
        setMessage({
          message: "Failed to get tasks: " + message,
          level: Level.Err
        })
      } else {
        setMessage({
          message: "Failed to get tasks: " + error,
          level: Level.Err
        })
      }
    }
  }

  const validTodo = () => {
    // Pretty rudimentary for now. Only reason we only have to do this is because pocketbase gives
    // non-specific errors for missing fields...
    if (!newTodo.title) {
      setMessage({
        message: "Title is a required field",
        level: Level.Err
      })
      return false
    }
    if (!newTodo.punishment_email) {
      setMessage({
        message: "Punisment email is a required field",
        level: Level.Err
      })
      return false
    }

    return true
  }

  const addTodo = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!validTodo()) {
      return;
    }
    try {
      await pb.collection('todos').create(newTodo)
      // Clear out the form
      setNewTodo(new Todo({ title: '', description: '', completed: false, user: id, punishment_email: '', due: new Date()}))
    } catch (error) {
      if (error instanceof ClientResponseError) {
        let message  = (Object.values(error.response.data)[0] as {[key: string]: any}).message || "Unknown Error";
        setMessage({
          message: "Failed to create new task: " + message,
          level: Level.Err
        })
      } else {
        setMessage({
          message: "Failed to create new task: " + error,
          level: Level.Err
        })
      }
    }

    fetchTodos()
  }

  const toggleComplete = async (id: string, completed: boolean) => {
    try {
      await pb.collection('todos').update(id, {
        completed: !completed
      })
      fetchTodos()
    } catch (error) {
      if (error instanceof ClientResponseError) { 
        setMessage({
          message: error.message,
          level: Level.Err
        })
      } else {
        setMessage({
          message: "something went wrong",
          level: Level.Err
        })
      }
    }
  }

  const deleteTodo = async (id: string) => {
    console.log(id)
    try {
      await pb.collection('todos').delete(id)
      fetchTodos()
    } catch (error) {
      if (error instanceof ClientResponseError) { 
        setMessage({
          message: error.message,
          level: Level.Err
        })
      } else {
        setMessage({
          message: "something went wrong",
          level: Level.Err
        })
      }
    }
  }

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    if (
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      return currentDate.getTime() < selectedDate.getTime();
    }

    return true;
  };

  const timerExpired = async () => {
    await new Promise(r => setTimeout(r, 7000));
    fetchTodos();
  }


  return (
    <div className="max-w-4xl mx-auto p-6">
      <Logo content="todo"/>
      <Message message={message.message} level={message.level}/>
      <form onSubmit={addTodo} className="mb-8">
        <div className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Todo title"
            value={newTodo.title}
            onChange={(e) => setNewTodo({ ...newTodo, title: e.target.value })}
            className="p-2 border rounded"
          />
          <textarea
            placeholder="Description (optional)"
            value={newTodo.description}
            onChange={(e) => setNewTodo({ ...newTodo, description: e.target.value })}
            className="p-2 border rounded"
          />
          <input
            type="text"
            placeholder="Punishment email"
            value={newTodo.punishment_email}
            onChange={(e) => setNewTodo({ ...newTodo, punishment_email: e.target.value })}
            className="p-2 border rounded"
          />

          <DatePicker
                  selected={newTodo.due}
                  onChange={(date) => {
                    if (date) {
                      setNewTodo({ ...newTodo, due: date})
                    }
                  }}
                  showTimeSelect
                  minDate={new Date()}
                  minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                  maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                  filterTime={filterPassedTime}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select date and time"
                  className="w-full px-3 py-2 border rounded-md"
                  timeIntervals={15} // 15-minute intervals
                  timeCaption="Time"
                  timeFormat="h:mm aa"
                  // Additional useful props:
                  // showSeconds={false}
                  // timeIntervals={30} // Change to 30-minute intervals
                  // includeTimes={[]} // Specific allowed times
                  // excludeTimes={[]} // Specific blocked times
                />
          
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
          >
            <Plus size={20} /> Add Todo
          </button>
        </div>
      </form>
      <div className="h-px bg-gray-300"></div>
      <div className="space-y-4">
        {todos.map((todo) => (
          <div
            key={todo.id}
            className={`p-4 border rounded shadow-sm ${
              todo.completed ? 'bg-gray-50' : 'bg-white'
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <h3 className={`text-xl ${todo.completed ? 'line-through text-gray-500' : ''}`}>
                  {todo.title}
                </h3>
                {todo.description && (
                  <p className="text-gray-600 mt-2">{todo.description}</p>
                )}
                <p className="text-gray-600 mt-2">{todo.punishment_email}</p>
              </div>
              <div className="flex items-center gap-2">
                <CountdownTimer initialTimeInSeconds={Math.floor(Math.abs(new Date().getTime() - new Date(todo.due).getTime()) / 1000)} onComplete={timerExpired}/>
                <button
                  onClick={() => toggleComplete(todo.id, todo.completed)}
                  className={`p-2 rounded ${
                    todo.completed ? 'bg-gray-500' : 'bg-green-500'
                  } text-white`}
                >
                  {todo.completed ? <X size={20} /> : <Check size={20} />}
                </button>
                <button
                  onClick={() => deleteTodo(todo.id)}
                  className="p-2 bg-red-500 text-white rounded"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="h-px bg-gray-300"></div>
      <div className="flex flex-col items-center">
        <button
          onClick={ () => {
            pb.authStore.clear();
            setPage(Page.Home);
          }}
          className="bg-red-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-red-600"
        >
          Logout
        </button>
      </div>
    </div>
  )
}

export default TodoList
