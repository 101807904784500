import PocketBase from 'pocketbase';
import {useEffect} from 'react';
import { Page } from './App';

export default function Verify({pb, setPage}: {pb: PocketBase, setPage: Function}) {

  // useEffect(() => {
  //   if (!pb.authStore.isValid || !pb.authStore.model) {
  //     setPage(Page.Login)
  //     return
  //   }
  //   if (pb.authStore.model.verified) {
  //     setPage(Page.Todo)
  //   }
  //   pb.collection('users').subscribe(pb.authStore.model.id, (e) => {
  //     if (e.record.verified) {
  //       pb.collection('users').unsubscribe()
  //       setPage(Page.Todo)
  //     }
  //   })
  // }, []);

  return (
    <>
      <b> Check your email for verification </b>
        <button
          onClick={ () => {
            setPage(Page.Login);
          }}
          className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
        >
          Go To Login
        </button>
    </>
  )
}
