import { useState } from "react";
import PocketBase, {ClientResponseError} from "pocketbase";

import { Page } from "./App";
import Message, { Level } from "./Message";
import Logo from "./Logo";

function Signup({pb, setPage}: {pb: PocketBase, setPage: Function}) {
  const [formData, setFormData] = useState({email: "", password: "", passwordConfirm: ""});
  const [message, setMessage] = useState({message: "", level: Level.Info})

  const fetchSignup = async (e: any) => {
    e.preventDefault()
    try {
      await pb.collection('users').create(formData);
      await pb.collection('users').requestVerification(formData.email)
      
      setPage(Page.Verify)
    } catch (error) {
      console.error(error)
      if (error instanceof ClientResponseError) {
        if (Object.hasOwn(error.data.data, 'email')) {
          setMessage({
            message: error.data.data.email.message,
            level: Level.Err
          })
          return;
        }
        if (Object.hasOwn(error.data.data, 'password')) {
          setMessage({
            message: "Password: " + error.data.data.password.message,
            level: Level.Err
          })
          return;
        }
        if (Object.hasOwn(error.data.data, 'passwordConfirm')) {
          setMessage({
            message: "Confirm password: " + error.data.data.passwordConfirm.message,
            level: Level.Err
          })
          return;
        }
      } else {
        setMessage({
          message: "something went TERRIBLY wrong",
          level: Level.Err
        })
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <Logo content="todo me in"/>
      <h1 className="text-3xl font-bold mb-8">Signup</h1>
      <Message message={message.message} level={message.level}/>
      <form onSubmit={fetchSignup} className="mb-8 flex flex-col gap-4">
        <input
          type="text"
          placeholder="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="password"
          placeholder="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="password"
          placeholder="confirm password"
          value={formData.passwordConfirm}
          onChange={(e) => setFormData({ ...formData, passwordConfirm: e.target.value })}
          className="p-2 border rounded"
        />
        <div className="flex justify-center">
          <button onClick={() => {setPage(Page.Home)}}
                  className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
          >Back</button>
          <button
            type="submit"
            onClick={fetchSignup}
            className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
          >
            Signup
          </button>
        </div>
      </form>
    </div>

  );
}

export default Signup
