import PocketBase from 'pocketbase';
import Logo from './Logo';
import {Page} from './App';

export default function Home({pb, setPage}: {pb: PocketBase, setPage: Function}) {
  return (
    <>
      <Logo content="todo me in" subtext="get it done or else"/>
      <div className="flex justify-center">
        <button onClick={() => {setPage(Page.Login)}}
                className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
        >Login</button>
        <button onClick={() => {setPage(Page.Signup)}}
                className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
        >Sign-up</button>
      </div>
    </>
  );
}
