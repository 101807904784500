
import { CircleX } from 'lucide-react';
import { useState } from 'react';

export enum Level {
  Err=1,
  Info
}

function Icon({level}: {level: Level}) {
  switch(level) {
    case Level.Err:
      return <CircleX />
    default:
      return <></>
  }
}

function Message({message, level}: {message: string, level: Level}) {
  let color = "";
  switch (level) {
    case Level.Err:
      color = "bg-red-200 border-red-300";
      break;
    case Level.Info:
      color = "bg-gray-200 border-gray-300";
      break;
    default:
      color = "";
  }
  if (message != "") {
    return (
      <div className={"flex border-2 p-2 my-4 rounded-lg " + color}>
        <Icon level={level}/>
        <span className="ml-2"> {message}</span>
      </div>
    );
  } else {
    return <></>
  }
}

export default Message
