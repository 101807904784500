import React from "react";

function getColors() {
  var r, g, b, bColorString, fColor;
  r = Math.floor((Math.random()*128)+128);
  g = Math.floor((Math.random()*128)+128);
  b = Math.floor((Math.random()*128)+128);
  fColor="#000";
  if(r*g*b>7000000) {
    r-=Math.floor((Math.random()*128)+1);
    g-=Math.floor((Math.random()*128)+1);
    b-=Math.floor((Math.random()*128)+1);
    fColor="#fff";
  }
  return ["rgb("+r+", "+g+", "+b+")", fColor];
}

function getTransform() {
  var x, y, d;
  x = Math.floor((Math.random()*4)+1);
  y = Math.floor((Math.random()*4)+1);
  d = Math.floor((Math.random()*8)-4);
  return "skew("+x+"deg, "+y+"deg) rotate("+d+"deg)";
}

function getFont() {
  const webSafeFonts = [
    { name: "Arial", css: 'font-family: Arial, sans-serif;' },
    { name: "Verdana", css: 'font-family: Verdana, sans-serif;' },
    { name: "Tahoma", css: 'font-family: Tahoma, sans-serif;' },
    { name: "Trebuchet MS", css: 'font-family: "Trebuchet MS", sans-serif;' },
    { name: "Helvetica", css: 'font-family: Helvetica, sans-serif;' },
    { name: "Arial Black", css: 'font-family: "Arial Black", sans-serif;' },
    { name: "Impact", css: 'font-family: Impact, sans-serif;' },
    { name: "Gill Sans", css: 'font-family: "Gill Sans", sans-serif;' },
    { name: "Times New Roman", css: 'font-family: "Times New Roman", serif;' },
    { name: "Georgia", css: 'font-family: Georgia, serif;' },
    { name: "Garamond", css: 'font-family: Garamond, serif;' },
    { name: "Palatino Linotype", css: 'font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;' },
    { name: "Baskerville", css: 'font-family: Baskerville, serif;' },
    { name: "Courier New", css: 'font-family: "Courier New", Courier, monospace;' },
    { name: "Lucida Console", css: 'font-family: "Lucida Console", Monaco, monospace;' },
    { name: "Andalé Mono", css: 'font-family: "Andale Mono", monospace;' },
    { name: "Brush Script MT", css: 'font-family: "Brush Script MT", cursive;' },
    { name: "Comic Sans MS", css: 'font-family: "Comic Sans MS", cursive;' },
    { name: "Luminari", css: 'font-family: Luminari, fantasy;' }
  ];
  var which = Math.floor((Math.random()*webSafeFonts.length));
  return webSafeFonts[which].name;
}

function getSize(big: boolean) {
  const add = big ? 45 : 15;
  const variance = big ? 25 : 15;
  return Math.floor((Math.random()*variance)+add)+"px";
}

function getPadding() {
    return Math.floor((Math.random()*4)+4)+"px";
}

function getMargin() {
     return Math.floor((Math.random()*6)+6)+"px";
}

function character(c: string, key: number, big: boolean){
  var colors = getColors();
  const isCharacter = /[a-zA-Z0-9-]/
  if (isCharacter.test(c)) {
      const styles: React.CSSProperties = {
        backgroundColor: colors[0],
        color: colors[1],
        fontFamily: getFont(),
        fontSize: getSize(big),
        paddingLeft: getPadding(),
        paddingRight: getPadding(),
        marginRight: getMargin(),
        transform: getTransform()
      }
      return <span style={styles} key={key} className='letter inline-block'>{c}</span>
  }
  if(c == "\n") {
    const styles: React.CSSProperties = {backgroundColor: "transparent"}
    return (<br style={styles}/>)
  }
  if(c == " ") {
    const styles: React.CSSProperties = {backgroundColor: "transparent"}
    return (<div style={styles} className='w-6 inline-block'> </div>);
  }
};

export default function Logo({content, subtext}: {content: string, subtext?: string}) {
  return (
    <div className="m-6">
      <div className="text-center">
        {content.split(' ').map((w, i) => {
          return (
            <div key={i} className="inline-block m-2">
              {w.split('').map((c, j) => {
                return character(c, j, true);
              })}
            </div>
          );
        })}
      </div>
      { subtext && 
        <div className="text-center">
          {subtext.split(' ').map((w, i) => {
            return (
              <div key={i} className="inline-block m-2">
                {w.split('').map((c, j) => {
                  return character(c, j, false);
                })}
              </div>
            );
          })}
        </div>
      }
    </div>
  )

}
