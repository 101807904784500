import { Dispatch, SetStateAction, useState } from "react";
import PocketBase, {ClientResponseError}  from 'pocketbase';

import { Page } from "./App";
import Message, { Level } from "./Message";
import Logo from "./Logo";


function Login({pb, setPage}: {pb: PocketBase, setPage: Function}) {
  const [formData, setFormData] = useState({email: "", password: ""});
  const [message, setMessage] = useState({message: "", level: Level.Info})

  const fetchLogin = async (e: any) => {
    e.preventDefault();
    try {
      const output = await pb.collection('users').authWithPassword(
        formData.email,
        formData.password
      );
      if (pb.authStore.isValid && pb.authStore.model) {
        if (pb.authStore.model.verified) {
          setPage(Page.Todo)
        } else {
          try{
            await pb.collection('users').requestVerification(pb.authStore.model.email)
          } catch(error) {
            // TODO: better handling of error
            setPage(-1);
          }
          setPage(Page.Verify)
        }
      }
    } catch (error) {
      if (error instanceof ClientResponseError) { 
        if (Object.hasOwn(error.data.data, 'identity')) {
          setMessage({
            message: "Email: " + error.data.data.identity.message,
            level: Level.Err
          })
          return;
        }
        if (Object.hasOwn(error.data.data, 'password')) {
          setMessage({
            message: "Password: " + error.data.data.password.message,
            level: Level.Err
          })
          return;
        }
        setMessage({
          message: error.message,
          level: Level.Err
        })
      } else {
        setMessage({
          message: "something went wrong",
          level: Level.Err
        })
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <Logo content="todo me in"/>
      <h1 className="text-3xl font-bold mb-8">Login</h1>
      <Message message={message.message} level={message.level}/>
      <form onSubmit={fetchLogin} className="mb-8 flex flex-col gap-4">
        <input
          type="text"
          placeholder="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="p-2 border rounded"
        />
        <input
          type="password"
          placeholder="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          className="p-2 border rounded"
        />
        <div className="flex justify-center">
          <button onClick={() => {setPage(Page.Home)}}
                  className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
          >Back</button>
          <button
            type="submit"
            onClick={fetchLogin}
            className="bg-blue-500 text-white p-2 m-2 rounded flex items-center justify-center gap-2 hover:bg-blue-600"
          >
            Login
          </button>
        </div>
      </form>
    </div>

  );
}

export default Login
